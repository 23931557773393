import {
	GET_QRCODE_REQUEST,
	GET_QRCODE_SUCCESS,
	GET_QRCODE_FAIL,
} from "../constants/qrCode";

function QrCodeReducer(state, action) {
	if (typeof state === "undefined") {
		state = {};
	}
	switch (action.type) {
		case GET_QRCODE_REQUEST:
			return { isFetching: true };
		case GET_QRCODE_SUCCESS:
			return {
				isFetching: false,
				qrCode: action.payload.data,
			};
		case GET_QRCODE_FAIL:
			return { error: action.payload };
		default:
			return state;
	}
}

export { QrCodeReducer };
