import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./Login.scss";
import { LoginIcon } from "../Icons.jsx";
import { Form, Button, Input } from "antd";
import { login } from "../../actions/session";
import Cookie from "js-cookie";

const LoginPage = (props) => {
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const { error, isAuthenticated } = useSelector((state) => state.login);

	const handleSubmit = (values) => {
		Cookie.set("loginParams", "");
		dispatch(login(values));
	};

	useEffect(() => {
		if (isAuthenticated) {
			props.history.push("/");
		}
	}, [isAuthenticated]);

	return (
		<div className="form-page-container login-page">
			<div className="form-page-box">
				<div className="form-page-header">
					<h2 className="form-page-header-title">Log In</h2>
					<div className="error-container">
						{error && <div className="error-message">{error}</div>}
					</div>
				</div>
				<div className="form-page-content">
					<Form
						form={form}
						colon={false}
						onFinish={handleSubmit}
						requiredMark={false}
					>
						<div className="form-container">
							<Form.Item
								label="Store ID"
								name="store_id"
								rules={[{ required: true, message: "Required" }]}
							>
								<Input />
							</Form.Item>
							<Form.Item
								label="Staff ID"
								name="staff_id"
								rules={[{ required: true, message: "Required" }]}
							>
								<Input />
							</Form.Item>
							<div className="form-actions">
								<Button
									type="primary"
									className="btn btn-primary"
									htmlType="submit"
								>
									Log In
									<LoginIcon />
								</Button>
							</div>
						</div>
					</Form>
				</div>
			</div>
		</div>
	);
};

export default LoginPage;
