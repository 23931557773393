import React from "react";

const DownloadIcon = (props) => {
	return (
		<svg
			xmlns="https://www.w3.org/2000/svg"
			enableBackground="new 0 0 24 24"
			viewBox="0 0 24 24"
			className={props.className || ""}
		>
			<g>
				<rect />
			</g>
			<g>
				<path d="M5,20h14v-2H5V20z M19,9h-4V3H9v6H5l7,7L19,9z" />
			</g>
		</svg>
	);
};

const ArrowBackIos = (props) => {
	return (
		<svg
			xmlns="https://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			fill="black"
			className={props.className || ""}
		>
			<path d="M0 0h24v24H0z" fill="none" />
			<path d="M11.67 3.87L9.9 2.1 0 12l9.9 9.9 1.77-1.77L3.54 12z" />
		</svg>
	);
};

const LoginIcon = (props) => {
	return (
		<svg
			xmlns="https://www.w3.org/2000/svg"
			enableBackground="new 0 0 24 24"
			viewBox="0 0 24 24"
			className={props.className || ""}
		>
			<g>
				<rect />
			</g>
			<g>
				<path
					d="M11,7L9.6,8.4l2.6,2.6H2v2h10.2l-2.6,2.6L11,17l5-5L11,7z M20,19h-8v2h8c1.1,0,2-0.9,2-2V5c0-1.1-0.9-2-2-2h-8v2h8V19z"
					color="currentColor"
				/>
			</g>
		</svg>
	);
};

const FileUploadIcon = (props) => {
	return (
		<svg
			xmlns="https://www.w3.org/2000/svg"
			enableBackground="new 0 0 24 24"
			viewBox="0 0 24 24"
			className={props.className || ""}
		>
			<g>
				<rect />
			</g>
			<g>
				<path d="M5,20h14v-2H5V20z M5,10h4v6h6v-6h4l-7-7L5,10z" />
			</g>
		</svg>
	);
};

export { DownloadIcon, ArrowBackIos, LoginIcon, FileUploadIcon };
