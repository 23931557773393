import { Button, message, Spin } from "antd";
import React, { useEffect } from "react";
import QRCode from "react-qr-code";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Cookie from "js-cookie";
import { login, logout } from "../../actions/session";
import "./QRCode.scss";
import { INTERVAL_TIME } from "../../constants";
import { LogoutOutlined, ReloadOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

const QRCodePage = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const { userInfo, isFetching } = useSelector((state) => state.login);

	const handleLogout = () => {
		Cookie.set("userInfo", "");
		Cookie.set("eys_login_info", "");
		dispatch(logout());
		history.push("/login");
	};

	const handleRefresh = () => {
		Cookie.set("userInfo", "");
		const loginInfo = Cookie.get("eys_login_info");
		const loginInfoParsed = JSON.parse(loginInfo);
		if (loginInfoParsed) {
			dispatch(login(loginInfoParsed));
		} else {
			message.info("Please try to login again.");
		}
	};

	useEffect(() => {
		const interval = setInterval(() => {
			var date = new Date();
			if (date.getHours() === 0 && date.getMinutes() === 5) {
				handleRefresh();
			}
		}, INTERVAL_TIME);

		return () => clearInterval(interval);
	}, []);

	useEffect(() => {
		handleRefresh();
	}, []);

	return (
		<div className="qr-code-container">
			<div className="top-header">
				{userInfo && (
					<Button type="link" onClick={handleLogout} className="btn btn-link">
						Logout <LogoutOutlined />
					</Button>
				)}
			</div>
			<div className="qrcode-section">
				<h2 className="title-page">{userInfo && userInfo.name}</h2>
				<div className="qrcode-content">
					<Spin spinning={isFetching}>
						{userInfo && <QRCode value={userInfo.code} size={312} />}
					</Spin>
				</div>
				<div className="actions">
					<Button type="link" onClick={handleRefresh} className="btn btn-link">
						Refresh <ReloadOutlined />
					</Button>
				</div>
			</div>
		</div>
	);
};

export default QRCodePage;
