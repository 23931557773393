export const PAGE_SIZE = 20;
export const TIME_FORMAT_FRONTEND = "hh:mm a";
export const PLAN_DATE_FORMAT_FRONTEND = "ddd, DD MMM, YYYY";
export const DATE_FORMAT_FRONTEND = "ddd, DD MMM, YYYY";
export const DATETIME_FORMAT_FRONTEND = "h:mm A, MMM Do, YYYY";
export const PICKUPDATE_FORMAT_FRONTEND = "ddd, DD MMM, YYYY";
export const DATE_FORMAT_BACKEND = "YYYY/MM/DD";
export const DATE_FORMAT_QUERY = "YYYY-MM-DD";
export const ENCRYPT_MODE = false;
// export const INTERVAL_TIME = 3600000;
export const INTERVAL_TIME = 60000;
