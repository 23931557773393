import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import Cookie from "js-cookie";

import { loginReducer } from "./reducers/session";

import { LOGOUT_REQUEST } from "./constants/session";
import { RESET_QRCODE_REQUEST } from "./constants/qrCode";
import { QrCodeReducer } from "./reducers/qrCode";

const userInfo = Cookie.getJSON("userInfo") || "";
const initalState = { login: { userInfo } };

const reducer = combineReducers({
	login: loginReducer,
	qrCode: QrCodeReducer,
});

const rootReducer = (state, action) => {
	switch (action.type) {
		case LOGOUT_REQUEST:
			state.login = {};
			break;
		case RESET_QRCODE_REQUEST:
			state.qrCode = {};
			break;
	}

	return reducer(state, action);
};

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middleware = applyMiddleware(thunk);

const store = createStore(
	rootReducer,
	initalState,
	composeEnhancer(middleware),
);

export default store;
