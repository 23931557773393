import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";
import { SessionsLayout, MainLayout } from "../components/Layouts";
import { LoginPage, QRCodePage } from "../components/Pages";

const index = () => {
	return (
		<Router>
			<Switch>
				<Route path="/login" exact>
					<SessionsLayout>
						<Route exact path="/login" component={LoginPage} />
					</SessionsLayout>
				</Route>
				<PrivateRoute path="/">
					<MainLayout>
						<Route exact path="/" component={QRCodePage} />
					</MainLayout>
				</PrivateRoute>
			</Switch>
		</Router>
	);
};
export default index;
