import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAIL } from "../constants/session";

function loginReducer(state, action) {
	if (typeof state === "undefined") {
		state = {};
	}
	switch (action.type) {
		case LOGIN_REQUEST:
			return { isAuthenticated: false, isFetching: true };
		case LOGIN_SUCCESS:
			return {
				isAuthenticated: true,
				userInfo: action.payload.data,
				isFetching: false,
			};
		case LOGIN_FAIL:
			return {
				isAuthenticated: false,
				error: action.payload,
				isFetching: false,
			};
		default:
			return state;
	}
}

export { loginReducer };
