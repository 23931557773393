import axios from "axios";
import { ENCRYPT_MODE } from "./constants";
import { handleEncryptData } from "./helpers/utils";

const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL,
});

axiosInstance.interceptors.request.use(async function (req) {
	if (ENCRYPT_MODE) {
		if (req.data) {
			const payloadEncryted = await handleEncryptData(req.data);
			req.data = payloadEncryted;
		}
	}

	return req;
});

export default axiosInstance;
