import React from "react";
import { Route, Redirect } from "react-router-dom";
import Cookie from "js-cookie";

function PrivateRoute({ children, ...rest }) {
	const userInfo = Cookie.get("userInfo");

	return (
		<Route
			{...rest}
			render={({ location }) =>
				userInfo ? (
					children
				) : (
					<Redirect
						to={{
							pathname: "/login",
							state: { from: location },
						}}
					/>
				)
			}
		/>
	);
}

export default PrivateRoute;
